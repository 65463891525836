import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [

  // Rutas de acceso público
  {
    path: "/",
    redirect: "/login",
    component: () => import("@/views/layout/ExternalLayout"),
    props: {
      color: '#926da7'
    },
    
    children: [
      // {
      //   path: "/inicio",
      //   name: "inicio-informacion",
      //   component: () => import("@/view/pages/dashboard/PublicDashboard.vue")
      // },
      {
        path: '/register',
        name: 'donor-register',
        component: () => import(/* webpackChunkName: "login" */ '../views/auth/RegisterView.vue')
      },
      {
        path: '/login',
        name: 'donor-login',
        component: () => import(/* webpackChunkName: "login" */ '../views/auth/LoginView.vue')
      },
      {
        path: '/forgot',
        name: 'donor-forgot',
        component: () => import(/* webpackChunkName: "login" */ '../views/auth/PasswordResetView.vue')
      },
    ]
  },

  // Rutas intended parents
  {
    path: "/parent",
    // redirect: "/inicio",
    component: () => import("@/views/layout/ExternalLayout"),
    props: {
      color: '#1d8c83'
    },
    children: [
      {
        path: 'register',
        name: 'parent-register',
        component: () => import(/* webpackChunkName: "login" */ '../views/iparent/RegisterView.vue')
      },
    ]
  },
  


   // Rutas de acceso privado
   {
    path: "/app/donor",
    // redirect: "/inicio",
    component: () => import("@/views/layout/InternalLayout"),
    props: {
      color: '#926da7'
    },

    children: [
      {
        path: 'home',
        name: 'donor-home',
        component: () => import(/* webpackChunkName: "DonorHomeView" */ '../views/donor/DonorHomeView.vue'),
        meta: {requiresAuth: true}
          
      },
      {
        path: 'contact',
        name: 'donor-contact',
        component: () => import(/* webpackChunkName: "DonorHomeView" */ '../views/donor/DonorContactView.vue'),
        meta: {requiresAuth: true}
          
      },

      {
        path: 'pictures',
        name: 'donor-pictures',
        component: () => import(/* webpackChunkName: "DonorHomeView" */ '../views/donor/DonorPhotoView.vue'),
        meta: {requiresAuth: true}
          
      },
      
    ],
    
  },
  {
    path: "/app/parent",
    // redirect: "/inicio",
    component: () => import("@/views/layout/InternalLayout"),
    props: {
      color: '#1d8c83'
    },

    children: [
      {
        path: 'home',
        name: 'parent-home',
        component: () => import(/* webpackChunkName: "ParentHomeView" */ '../views/iparent/ParentHomeView.vue'),
        meta: {requiresAuth: true}
      },
      {
        path: 'search',
        name: 'donor-search',
        component: () => import(/* webpackChunkName: "DonorSearch" */ '../views/iparent/DonorSearch.vue'),
        meta: {requiresAuth: true}
      },
      {
        path: 'search/details/:donorId',
        name: 'donor-search-details',
        component: () => import(/* webpackChunkName: "DonorSearchDetails" */ '../views/iparent/DonorSearchDetails.vue'),
        meta: {requiresAuth: true}
      }
    ]
  },
  

  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  
  
 
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
