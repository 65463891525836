const state = {
  countries: []
};

const getters = {
  allCountries(state) {
    return state.countries;
  }
};

const actions = {
  fetchCountries ({ commit }) {
    let data = [
        { id: 404, name: 'Select Option', countryCode: "" },
        { id: 1, name: "AFGHANISTAN", countryCode: "AF" },
        { id: 2, name: "ALBANIA", countryCode: "AL" },
        { id: 3, name: "ALGERIA", countryCode: "DZ" },
        { id: 4, name: "ANDORRA", countryCode: "AD" },
        { id: 5, name: "ANGOLA", countryCode: "AO" },
        { id: 6, name: "ANTIGUA AND BARBUDA", countryCode: "AG" },
        { id: 7, name: "ARGENTINA", countryCode: "AR" },
        { id: 8, name: "ARMENIA", countryCode: "AM" },
        { id: 9, name: "AUSTRALIA", countryCode: "AU" },
        { id: 10, name: "AUSTRIA", countryCode: "AT" },
        { id: 11, name: "AZERBAIJAN", countryCode: "AZ" },
        { id: 12, name: "BAHAMAS", countryCode: "BS" },
        { id: 13, name: "BAHRAIN", countryCode: "BH" },
        { id: 14, name: "BANGLADESH", countryCode: "BD" },
        { id: 15, name: "BARBADOS", countryCode: "BB" },
        { id: 16, name: "BELARUS", countryCode: "BY" },
        { id: 17, name: "BELGIUM", countryCode: "BE" },
        { id: 18, name: "BELIZE", countryCode: "BZ" },
        { id: 19, name: "BENIN", countryCode: "BJ" },
        { id: 20, name: "BHUTAN", countryCode: "BT" },
        { id: 21, name: "BOLIVIA", countryCode: "BO" },
        { id: 22, name: "BOSNIA AND HERZEGOVINA", countryCode: "BA" },
        { id: 23, name: "BOTSWANA", countryCode: "BW" },
        { id: 24, name: "BRAZIL", countryCode: "BR" },
        { id: 25, name: "BRUNEI", countryCode: "BN" },
        { id: 26, name: "BULGARIA", countryCode: "BG" },
        { id: 27, name: "BURKINA FASO", countryCode: "BF" },
        { id: 28, name: "BURUNDI", countryCode: "BI" },
        { id: 29, name: "CABO VERDE", countryCode: "CV" },
        { id: 30, name: "CAMBODIA", countryCode: "KH" },
        { id: 31, name: "CAMEROON", countryCode: "CM" },
        { id: 32, name: "CANADA", countryCode: "CA" },
        { id: 33, name: "CENTRAL AFRICAN REPUBLIC", countryCode: "CF" },
        { id: 34, name: "CHAD", countryCode: "TD" },
        { id: 35, name: "CHILE", countryCode: "CL" },
        { id: 36, name: "CHINA", countryCode: "CN" },
        { id: 37, name: "COLOMBIA", countryCode: "CO" },
        { id: 38, name: "COMOROS", countryCode: "KM" },
        { id: 39, name: "CONGO (Congo-Brazzaville)", countryCode: "CG" },
        { id: 40, name: "COSTA RICA", countryCode: "CR" },
        { id: 41, name: "CROATIA", countryCode: "HR" },
        { id: 42, name: "CUBA", countryCode: "CU" },
        { id: 43, name: "CYPRUS", countryCode: "CY" },
        { id: 44, name: "CZECHIA (Czech Republic)", countryCode: "CZ" },
        { id: 45, name: "DEMOCRATIC REPUBLIC OF THE CONGO", countryCode: "CD" },
        { id: 46, name: "DENMARK", countryCode: "DK" },
        { id: 47, name: "DJIBOUTI", countryCode: "DJ" },
        { id: 48, name: "DOMINICA", countryCode: "DM" },
        { id: 49, name: "DOMINICAN REPUBLIC", countryCode: "DO" },
        { id: 50, name: "ECUADOR", countryCode: "EC" },
        { id: 51, name: "EGYPT", countryCode: "EG" },
        { id: 52, name: "EL SALVADOR", countryCode: "SV" },
        { id: 53, name: "EQUATORIAL GUINEA", countryCode: "GQ" },
        { id: 54, name: "ERITREA", countryCode: "ER" },
        { id: 55, name: "ESTONIA", countryCode: "EE" },
        { id: 56, name: "ESWATINI", countryCode: "SZ" },
        { id: 57, name: "ETHIOPIA", countryCode: "ET" },
        { id: 58, name: "FIJI", countryCode: "FJ" },
        { id: 59, name: "FINLAND", countryCode: "FI" },
        { id: 60, name: "FRANCE", countryCode: "FR" },
        { id: 61, name: "GABON", countryCode: "GA" },
        { id: 62, name: "GAMBIA", countryCode: "GM" },
        { id: 63, name: "GEORGIA", countryCode: "GE" },
        { id: 64, name: "GERMANY", countryCode: "DE" },
        { id: 65, name: "GHANA", countryCode: "GH" },
        { id: 66, name: "GREECE", countryCode: "GR" },
        { id: 67, name: "GRENADA", countryCode: "GD" },
        { id: 68, name: "GUATEMALA", countryCode: "GT" },
        { id: 69, name: "GUINEA", countryCode: "GN" },
        { id: 70, name: "GUINEA-BISSAU", countryCode: "GW" },
        { id: 71, name: "GUYANA", countryCode: "GY" },
        { id: 72, name: "HAITI", countryCode: "HT" },
        { id: 73, name: "HONDURAS", countryCode: "HN" },
        { id: 74, name: "HUNGARY", countryCode: "HU" },
        { id: 75, name: "ICELAND", countryCode: "IS" },
        { id: 76, name: "INDIA", countryCode: "IN" },
        { id: 77, name: "INDONESIA", countryCode: "ID" },
        { id: 78, name: "IRAN", countryCode: "IR" },
        { id: 79, name: "IRAQ", countryCode: "IQ" },
        { id: 80, name: "IRELAND", countryCode: "IE" },
        { id: 81, name: "ISRAEL", countryCode: "IL" },
        { id: 82, name: "ITALY", countryCode: "IT" },
        { id: 83, name: "JAMAICA", countryCode: "JM" },
        { id: 84, name: "JAPAN", countryCode: "JP" },
        { id: 85, name: "JORDAN", countryCode: "JO" },
        { id: 86, name: "KAZAKHSTAN", countryCode: "KZ" },
        { id: 87, name: "KENYA", countryCode: "KE" },
        { id: 88, name: "KIRIBATI", countryCode: "KI" },
        { id: 89, name: "KOSOVO", countryCode: "XK" },
        { id: 90, name: "KUWAIT", countryCode: "KW" },
        { id: 91, name: "KYRGYZSTAN", countryCode: "KG" },
        { id: 92, name: "LAOS", countryCode: "LA" },
        { id: 93, name: "LATVIA", countryCode: "LV" },
        { id: 94, name: "LEBANON", countryCode: "LB" },
        { id: 95, name: "LESOTHO", countryCode: "LS" },
        { id: 96, name: "LIBERIA", countryCode: "LR" },
        { id: 97, name: "LIBYA", countryCode: "LY" },
        { id: 98, name: "LIECHTENSTEIN", countryCode: "LI" },
        { id: 99, name: "LITHUANIA", countryCode: "LT" },
        { id: 100, name: "LUXEMBOURG", countryCode: "LU" },
        { id: 101, name: "MADAGASCAR", countryCode: "MG" },
        { id: 102, name: "MALAWI", countryCode: "MW" },
        { id: 103, name: "MALAYSIA", countryCode: "MY" },
        { id: 104, name: "MALDIVES", countryCode: "MV" },
        { id: 105, name: "MALI", countryCode: "ML" },
        { id: 106, name: "MALTA", countryCode: "MT" },
        { id: 107, name: "MARSHALL ISLANDS", countryCode: "MH" },
        { id: 108, name: "MAURITANIA", countryCode: "MR" },
        { id: 109, name: "MAURITIUS", countryCode: "MU" },
        { id: 110, name: "MEXICO", countryCode: "MX" },
        { id: 111, name: "MICRONESIA", countryCode: "FM" },
        { id: 112, name: "MOLDOVA", countryCode: "MD" },
        { id: 113, name: "MONACO", countryCode: "MC" },
        { id: 114, name: "MONGOLIA", countryCode: "MN" },
        { id: 115, name: "MONTENEGRO", countryCode: "ME" },
        { id: 116, name: "MOROCCO", countryCode: "MA" },
        { id: 117, name: "MOZAMBIQUE", countryCode: "MZ" },
        { id: 118, name: "MYANMAR (formerly Burma)", countryCode: "MM" },
        { id: 119, name: "NAMIBIA", countryCode: "NA" },
        { id: 120, name: "NAURU", countryCode: "NR" },
        { id: 121, name: "NEPAL", countryCode: "NP" },
        { id: 122, name: "NETHERLANDS", countryCode: "NL" },
        { id: 123, name: "NEW ZEALAND", countryCode: "NZ" },
        { id: 124, name: "NICARAGUA", countryCode: "NI" },
        { id: 125, name: "NIGER", countryCode: "NE" },
        { id: 126, name: "NIGERIA", countryCode: "NG" },
        { id: 127, name: "NORTH KOREA", countryCode: "KP" },
        { id: 128, name: "NORTH MACEDONIA", countryCode: "MK" },
        { id: 129, name: "NORWAY", countryCode: "NO" },
        { id: 130, name: "OMAN", countryCode: "OM" },
        { id: 131, name: "PAKISTAN", countryCode: "PK" },
        { id: 132, name: "PALAU", countryCode: "PW" },
        { id: 133, name: "PALESTINE", countryCode: "PS" },
        { id: 134, name: "PANAMA", countryCode: "PA" },
        { id: 135, name: "PAPUA NEW GUINEA", countryCode: "PG" },
        { id: 136, name: "PARAGUAY", countryCode: "PY" },
        { id: 137, name: "PERU", countryCode: "PE" },
        { id: 138, name: "PHILIPPINES", countryCode: "PH" },
        { id: 139, name: "POLAND", countryCode: "PL" },
        { id: 140, name: "PORTUGAL", countryCode: "PT" },
        { id: 141, name: "QATAR", countryCode: "QA" },
        { id: 142, name: "ROMANIA", countryCode: "RO" },
        { id: 143, name: "RUSSIA", countryCode: "RU" },
        { id: 144, name: "RWANDA", countryCode: "RW" },
        { id: 145, name: "SAINT KITTS AND NEVIS", countryCode: "KN" },
        { id: 146, name: "SAINT LUCIA", countryCode: "LC" },
        { id: 147, name: "SAINT VINCENT AND THE GRENADINES", countryCode: "VC" },
        { id: 148, name: "SAMOA", countryCode: "WS" },
        { id: 149, name: "SAN MARINO", countryCode: "SM" },
        { id: 150, name: "SAO TOME AND PRINCIPE", countryCode: "ST" },
        { id: 151, name: "SAUDI ARABIA", countryCode: "SA" },
        { id: 152, name: "SENEGAL", countryCode: "SN" },
        { id: 153, name: "SERBIA", countryCode: "RS" },
        { id: 154, name: "SEYCHELLES", countryCode: "SC" },
        { id: 155, name: "SIERRA LEONE", countryCode: "SL" },
        { id: 156, name: "SINGAPORE", countryCode: "SG" },
        { id: 157, name: "SLOVAKIA", countryCode: "SK" },
        { id: 158, name: "SLOVENIA", countryCode: "SI" },
        { id: 159, name: "SOLOMON ISLANDS", countryCode: "SB" },
        { id: 160, name: "SOMALIA", countryCode: "SO" },
        { id: 161, name: "SOUTH AFRICA", countryCode: "ZA" },
        { id: 162, name: "SOUTH KOREA", countryCode: "KR" },
        { id: 163, name: "SOUTH SUDAN", countryCode: "SS" },
        { id: 164, name: "SPAIN", countryCode: "ES" },
        { id: 165, name: "SRI LANKA", countryCode: "LK" },
        { id: 166, name: "SUDAN", countryCode: "SD" },
        { id: 167, name: "SURINAME", countryCode: "SR" },
        { id: 168, name: "SWEDEN", countryCode: "SE" },
        { id: 169, name: "SWITZERLAND", countryCode: "CH" },
        { id: 170, name: "SYRIA", countryCode: "SY" },
        { id: 171, name: "TAIWAN", countryCode: "TW" },
        { id: 172, name: "TAJIKISTAN", countryCode: "TJ" },
        { id: 173, name: "TANZANIA", countryCode: "TZ" },
        { id: 174, name: "THAILAND", countryCode: "TH" },
        { id: 175, name: "TIMOR-LESTE", countryCode: "TL" },
        { id: 176, name: "TOGO", countryCode: "TG" },
        { id: 177, name: "TONGA", countryCode: "TO" },
        { id: 178, name: "TRINIDAD AND TOBAGO", countryCode: "TT" },
        { id: 179, name: "TUNISIA", countryCode: "TN" },
        { id: 180, name: "TURKEY", countryCode: "TR" },
        { id: 181, name: "TURKMENISTAN", countryCode: "TM" },
        { id: 182, name: "TUVALU", countryCode: "TV" },
        { id: 183, name: "UGANDA", countryCode: "UG" },
        { id: 184, name: "UKRAINE", countryCode: "UA" },
        { id: 185, name: "UNITED ARAB EMIRATES", countryCode: "AE" },
        { id: 186, name: "UNITED KINGDOM", countryCode: "GB" },
        { id: 187, name: "UNITED STATES OF AMERICA", countryCode: "US" },
        { id: 188, name: "URUGUAY", countryCode: "UY" },
        { id: 189, name: "UZBEKISTAN", countryCode: "UZ" },
        { id: 190, name: "VANUATU", countryCode: "VU" },
        { id: 191, name: "VATICAN CITY", countryCode: "VA" },
        { id: 192, name: "VENEZUELA", countryCode: "VE" },
        { id: 193, name: "VIETNAM", countryCode: "VN" },
        { id: 194, name: "YEMEN", countryCode: "YE" },
        { id: 195, name: "ZAMBIA", countryCode: "ZM" },
        { id: 196, name: "ZIMBABWE", countryCode: "ZW" }

    ];

    commit('setCountries', data)
  }
};

const mutations = {
  setCountries: (state, countries) => state.countries = countries,
};

export default {
  namespaced: true, 
  state,
  getters,
  actions,
  mutations
};

