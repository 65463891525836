import { createStore } from 'vuex';
import auth from './modules/auth';
import donors from './modules/donors';
import countries from './modules/countries';
import origins from './modules/origins';
import breadcrumbs from './modules/breadcrumbs';

const store = createStore({
  modules: {
    auth,
    donors,
    countries,
    origins,
    breadcrumbs
  }
});

export default store;