import { createApp  } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import JwtService from "@/store/jwt.service";
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import './assets/sass/main.scss';

import DropZone from 'dropzone-vue';

// optionally import default styles
import 'dropzone-vue/dist/dropzone-vue.common.css';




const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi', // This is already the default value - only for display purposes
  },
})

//const app = createApp(App);
// Crear un estado global reactivo para errores
//const errors = reactive([])
// Configuración del manejador global de errores
/*app.config.errorHandler = (err, vm, info) => {
  console.error(`Error: ${err.toString()}\nInfo: ${info}`)
  console.error(`Error in component:`, vm)
  console.error(`Error stack trace:`, err.stack)
  errors.push({ message: err.toString(), info, stack: err.stack })
}*/


router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.requiresAuth)) {
    
    // Ensure we checked auth before each page load.
    store.dispatch('auth/verifyAuth').then(res => {
      let token = JwtService.getToken();
      let donorId = JwtService.getDonorId();
      if (!token || !donorId) {
        return next({ name: 'donor-login' })
        //console.log(JwtService.getToken())
      }

      //?Validar que la ruta solicitada sea login
      if (to.path === "/login") {
        if (token && donorId) {
          if (res.status === 200) {
            
            //console.log(newRoute);
            return next({ name: 'donor-home' });
          } else {
            next();
          }
        }
        next();
      }
      
    });
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);

   return next();

  

  
});

createApp(App).use(vuetify).use(store).use(router).use(DropZone).mount('#app')
