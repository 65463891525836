
import JwtService from "../jwt.service";
import axios from "axios";


const resource_url = process.env.VUE_APP_CLIENTIFY_RESOURCE_AUTH_URL;
const c_user_name = process.env.VUE_APP_CLIENTIFY_USER_NAME;
const c_user_pass = process.env.VUE_APP_CLIENTIFY_USER_PASS;

const state = {
  users: [],
  user: {},
  isAuthenticated: !!JwtService.getToken(),
  errors: null
};

const getters = {
  allUsers(state) {
    return state.users;
  },

  getUser(state) {
    return state.user;
  },

  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {

  async login({ commit }) {
    const payload = {
      username: c_user_name,
      password: c_user_pass
    };
    return new Promise(resolve => {
      axios.post(resource_url, payload,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      ).then(res=>{
        if(res.status === 200 && res.data.token){
          commit('setUser', res.data)
          resolve({
            status: res.status,
            message: "login success",
          });
        } else {
          resolve({
            status: 404,
            message: "Error ocurred!",
            roles: null
          });
        }
      })
      .catch(error => {
        //console.log(error)
        commit('setError', error);
        return {
          status: 404,
          message: "Error!",
          roles: null
        };
      });
      
    });
    
  },

  logout({ commit }) {
    try {
    
      commit('purgeDonorAuth');
      commit('purgeAuth');
      
    } catch (error) {
      console.error('Error al obtener el token:', error);
    }
  },


  async verifyAuth({commit}) {
    if (JwtService.getToken() && JwtService.getDonorId()) {
      return new Promise(resolve => {
        
        axios.get("https://api.clientify.net/v1/settings/my-account/",
            {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + JwtService.getToken()
              }
            }
          )
          .then((data) => {
            if (data.status == 200 && data.data.count > 0) {
              resolve(data.data);
            } else {
              console.warn("purge...")
              commit('purgeAuth');
              resolve({
                status: 401,
                message: "Usuario sin permisos de acceso"
              });
                
              }
          })
        .catch(error => {
          //console.log(error)
          commit('setError', error);
          return {
            status: 404,
            message: "No se ha podido verificar el token!",
            roles: null
          };
        });
      });
    } else {
      console.warn("...");
      commit('purgeAuth');
      return {
        status: 404,
        message: "No se ha podido verificar el token!",
        roles: null
      };
    }
  }

};

const mutations = {
  setUsers: (state, users) => {state.users = users},

  setUser: (state, user) => {
    state.user = user;
    state.isAuthenticated = true;
    JwtService.saveToken(state.user.token);
    
  },

  purgeAuth: (state) => {
    state.isAuthenticated = false;
    JwtService.destroyToken();
    JwtService.destroyDonorId();
    
  },

  purgeDonorAuth: (state) => {
    state.donorAuth = {},
    JwtService.destroyDonorId();
  },

  setError: (state, error) => {
    state.errors = error;
  }
}

export default {
  namespaced: true, 
  state,
  getters,
  actions,
  mutations
};