export const setNotePayload = (type) => {

  let notePayload = {};

  switch (type) {
    case 'contact-information':
      notePayload = {
        name: "Contact Information",
        comment: "Contact information updated by donor"
      }
      break;
    case 'personal-information':
      notePayload = {
        name: "Personal Information",
        comment: "Personal information updated by donor"
      }
    break;

    case 'family-history':
      notePayload = {
        name: "Family History",
        comment: "Family History information updated by donor"
      }
      break;

    case 'medical-conditions':
      notePayload = {
        name: "Medical Conditions Information",
        comment: "Medical Conditions information updated by donor"
      }
      break;
    
    case 'reproductive-history':
      notePayload = {
        name: "Reproductive History Information",
        comment: "Reproductive History information updated by donor"
      }
      break;

    case 'education-occupation':
      notePayload = {
        name: "Education and Occupation Information",
        comment: "Education and Occupation information updated by donor"
      }
      break;

    case 'life-style':
      notePayload = {
        name: "General and Life Style",
        comment: "General and Life Style information updated by donor"
      }
      break;
    
    case 'picture-donor':
      notePayload = {
        name: "Picture",
        comment: "Picture(s) uploaded by donor"
      }
      break;
  
    default:
      break;
  }

  return notePayload;
}


export default {setNotePayload}