const ID_TOKEN_KEY = "id_token";
const ID_DONOR = "id_donor";
const NAME_DONOR = "name_donor";


export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const getDonorId = () => {
  return window.localStorage.getItem(ID_DONOR);
};

export const saveDonorId = token => {
  window.localStorage.setItem(ID_DONOR, token);
}

export const destroyDonorId = () => {
  window.localStorage.removeItem(ID_DONOR);
};

export const saveDonorName = name => {
  window.localStorage.setItem(NAME_DONOR, name);
}

export const getDonorName = () => {
  return window.localStorage.getItem(NAME_DONOR);
};

export const destroyDonorName = () => {
  window.localStorage.removeItem(NAME_DONOR);
};





export default { getToken, saveToken, destroyToken, getDonorId, saveDonorId, destroyDonorId, saveDonorName, getDonorName, destroyDonorName };