
import JwtService from "../jwt.service";
import axios from "axios";
import NotePayload from "@/untils/note-payload";
const resource_url = process.env.VUE_APP_CLIENTIFY_RESOURCE_URL;
const resource_backend_url = process.env.VUE_APP_BACKEND_RESOURCE_URL;

//const resource_url = "https://api.clientify.net/v1/contacts/";



const state = {
  contacts: [],
  donor: {},
  donorAuth: {},
  isDonorAuthenticated: !!JwtService.getDonorId(),
  error: "",
};


const getters = {
  allContacts: state => state.contacts,

  isDonorAuthenticated() {
    return !!JwtService.getDonorId()
  }
};


const actions = {
  async fetchContacts({ commit }) {
    const response = await axios.get(resource_url);
    commit('setDonors', response.data)
  },


  // Registar un donador
  async createDonor({ commit }, payload) {
    try {
      const response = await axios.post(resource_url, payload,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + JwtService.getToken()
          }
        }
      );
      commit('setDonor', response.data);
      return response.data;
    } catch (error) {
      console.error('Error al obtener el token:', error);
    }
  },


  // Get pass by email
  async sendForgotEmail({ commit }, data) {
    //return new Promise(resolve => {

      let customFields = data.results[0].custom_fields;
      const pass = customFields.find(item => 
        item.field == "password_eg"
      );
      
      let payload = {
        to: data.results[0].emails[0].email,
        subject: "Application password request",
        body: `Your password for Login in Costa Healt is ${pass.value}`
      }

    //   axios.post(`${resource_backend_url}send-email`, payload,
    //     {
    //       headers: {
    //         'Content-Type': 'application/json',
    //       }
    //     }
    //   ).then(response=> {
    //     if(response.data.count > 0) {
    //       commit('setDonorAuth', response.data);
    //       resolve({
    //         status: response.status,
    //         count: response.data.count,
    //         data: response.data,
    //         message: "Donor logging successfully"
    //       });
    //     } else {
    //       resolve({
    //         status: response.status,
    //         message: `Invalid email or password!`,
    //         roles: null
    //       });
    //     }
    //   }).catch(err=>{
    //     console.log(err)
    //     resolve({
    //       status: 404,
    //       message: "An error occurred while trying to login. Please contact an administrator.",
    //       roles: null
    //     });
    //   })
    // });

    try {
      const response = await axios.post(`${resource_backend_url}send-email`, payload,
        {
          headers: {
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Headers': 'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin',
            'Content-Type': 'application/json',
          }
        }
      );
      commit('setDonor', response.data);
      return response.data;
    } catch (error) {
      return({
        status: 404,
        message: "An error occurred while trying to login. Please contact an administrator. " + error,
        roles: null
      });
    }
  },


  // Get contact by email
  async getDonorByEmail({ commit }, email) {
    return new Promise(resolve => {
      let params = `?query=${email}&email=${email}`;
      
      axios.get(`${resource_url.replace(/\/$/, '')}${params}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + JwtService.getToken()
          }
        }
      ).then(response=> {
        if(response.data.count > 0) {
          commit('setDonorAuth', response.data);
          resolve({
            status: response.status,
            count: response.data.count,
            data: response.data,
            message: "Donor logging successfully"
          });
        } else {
          resolve({
            status: response.status,
            message: `Invalid email or password!`,
            roles: null
          });
        }
      }).catch(err=>{
        if(err.response.status) {
          resolve({
            status: err.response.status,
            message: `An error occurred while trying to login. Please contact an administrator.`,
            roles: null
          });
        }

        resolve({
          status: 404,
          message: "An error occurred while trying to login. Please contact an administrator.",
          roles: null
        });
      })
    });
      
   
  },


  // Hacer login con datos del donador
  async donorLogin({ commit }, payload) {
    
      commit('purgeDonorAuth');
      return new Promise(resolve => {
        //axios.get(`${resource_url}?email=${payload.email}&password_eg=${payload.password}`,
        axios.get(`${resource_url}?query=${payload.email}&email=${payload.email}&password_eg=${payload.password}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + JwtService.getToken()
          }
        }
      ).then(response=> {
            if(response.data.count > 0) {
              commit('setDonorAuth', response.data);
            

            resolve({
              status: response.status,
              count: response.data.count,
              data: response.data,
              message: "Donor logging successfully"
            })
          } else {
            resolve({
              status: response.status,
              message: `Invalid email or password!`,
              roles: null
            });
          }
        }).catch(err=> {
          commit('purgeAuth');
          commit('purgeDonorAuth');
          if(err.response) {
            if(err.response.status) {
              resolve({
                status: err.response.status,
                message: `An error occurred while trying to login. Please contact an administrator.`,
                roles: null
              });
            }
          }

          resolve({
            status: 404,
            message: "An error occurred while trying to login. Please contact an administrator.",
            roles: null
          });
        });
        
      });
      
  },

  // Obtener el donador por id
  async getDonorById({ commit }, donorId) {
    try {
      const response = await axios.get(`${resource_url}${donorId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + JwtService.getToken()
          }
        }
      );
      commit('setDonor', response.data);
      return response;
    } catch (error) {
      console.error('Error al obtener los datos del donador:', error);
    }
  },

  
  /**
   * Actualizar informacion del donador
   * @param {*} param0 
   * @param {*} payload 
   * @param {*} type personal-information, family-history, medical-conditions, reproductive-history, education-occupation, life-style, picture
   * @returns 
   */
  async updateDonor({ commit, dispatch  }, {payload, type}) {
    return new Promise(resolve => {
      axios.put(`${resource_url}${payload.donorId}`, payload,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + JwtService.getToken()
          }
        }
      ).then(response=>{
        let notePayload = NotePayload.setNotePayload(type);
        dispatch('createDonorNote', {notePayload: notePayload, donorId: payload.donorId}).then(()=>{
          commit('setDonor', response.data);
          resolve(response);
        }).catch(err=>{
          resolve({
            status: 500,
            message: err
          })
        })
      })
      .catch(err=>{
        resolve({
          status: 500,
          message: err
        })
      });
      
      //commit('createDonorNote', notePayload,payload.donorId);
    });
      
   
  },

   // Hacer búsqueda del donador
   async donorSearch({ commit }, payload) {
    let params = "?search_display_catalog_egg_donor=Yes&type_user=Egg Donor";
    
    if(Object.keys(payload).includes('hairColor')){
      params += `&cp_color_hair_eg=${payload.hairColor}`;
    }


    if(Object.keys(payload).includes('eyeColor')){
      if(params.length > 0)
        params += `&`;
        
        params += `cp_eye_color_eg=${payload.eyeColor}`;
    }

    if(Object.keys(payload).includes('race')){
      if(params.length > 0)
        params += `&`;
        
        params += `cp_ethnicity_eg=${payload.race}`;
    }

    // if(Object.keys(payload).includes('height')){
    //   if(params.length > 0)
    //     params += `&`;
        
    //     params += `cp_height_egg=${payload.height}`;
    // }

    if(Object.keys(payload).includes('minHeight')){
      if(params.length > 0)
        params += `&`;
        
        params += `cp_height_egg[gt]=${payload.minHeight}`;
    }

    if(Object.keys(payload).includes('maxHeight')){
      if(params.length > 0)
        params += `&`;
        
        params += `cp_height_egg[lt]=${payload.maxHeight}`;
    }


    if(Object.keys(payload).includes('educationLevel')){
      if(params.length > 0)
        params += `&`;
        
        params += `eo_level_edu_eg=${payload.educationLevel}`;
    }

    // if(Object.keys(payload).includes('country')){
    //   if(params.length > 0)
    //     params += `&`;
        
    //     params += `egg_country=${payload.country}`;
    // }

    //commit('purgeDonorAuth');
    return new Promise(resolve => {
      //axios.get(`${resource_url}?email=${payload.email}&password_eg=${payload.password}`,
      axios.get(`${resource_url}${params}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + JwtService.getToken()
        }
      }
    ).then(response=> {
          if(response.data.count > 0) {
            //commit('setDonorAuth', response.data);
          

          resolve({
            status: response.status,
            count: response.data.count,
            results: response.data.results,
            message: "Donor logging successfully"
          })
        } else {
          resolve({
            status: response.status,
            message: `Invalid email or password!`,
            roles: null
          });
        }
      }).catch(err=> {
        //commit('purgeAuth');
        //commit('purgeDonorAuth');
        commit('setError', err);
        if(err.response.status) {
          resolve({
            status: err.response.status,
            message: `An error occurred while trying to login. Please contact an administrator.`,
            roles: null
          });
        }

        resolve({
          status: 404,
          message: "An error occurred while trying to login. Please contact an administrator.",
          roles: null
        });
      });
      
    });    
  },

  // create contact(donor) note
  async createDonorNote({ commit }, {notePayload, donorId}){
    return new Promise(resolve => {
        //axios.get(`${resource_url}?email=${payload.email}&password_eg=${payload.password}`,
        axios.post(`${resource_url}${donorId}/note/`, notePayload,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + JwtService.getToken()
          }
        }
      ).then(response=> {
      
          if(response.status === 200) {
            //commit('setDonorAuth', response.data);
        
          resolve({
            status: response.status,
            message: "Donor note created successfully"
          })
        } else {
          resolve({
            status: response.status,
            message: "An error ocurred trying to create a note.",
          });
        }
      }).catch(err=> {
        //commit('purgeAuth');
        //commit('purgeDonorAuth');
        commit('setError', err);
      
        resolve({
          status: 500,
          message: "An error occurred while trying to create note. Please contact an administrator.",
          roles: null
        });
      });
      
    });  
  },

  /**
   * Upload donor picture(s)
   * @param {*} param0 
   * @param {*} payload 
   * @returns 
   */
  async createDonorPicture({ commit, dispatch }, payload) {
    //const fs = require('fs');
    const formData = new FormData();
    formData.append("contactId", payload.contactId);
    formData.append("name", payload.name);
    
    payload.images.forEach((file) => {
      formData.append('images', file);
    });
    const headers = {
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Headers': 'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin',
        'Content-Type': 'multipart/form-data',
    };

    return new Promise(resolve => {
      axios.post(`${resource_backend_url}upload/photos`, formData,{ headers })
      .then(response => {
        commit('setDonor', response.data);
        let notePayload = NotePayload.setNotePayload("picture-donor");
        dispatch('createDonorNote', {notePayload: notePayload, donorId: payload.contactId}).then(()=>{
          commit('setDonor', response.data);
          resolve(response.data)
        }).catch(err=>{
          resolve({
            status: 500,
            message: err
          })
        })
        
      })
      .catch(() => {
        resolve({
          status: 500,
          message: "An error occurred while trying to create note. Please contact an administrator.",
          roles: null
        });
      
      })
    });
  },

  /**
   * deleteDonorPicture donor picture
   * @param {*} param0 
   * @param {*} payload 
   * @returns 
   */
  async deleteDonorPicture({ commit, dispatch }, data) {
    //const fs = require('fs');
    
    
    const headers = {
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Headers': 'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin',
        'Content-Type': 'application/json',
    };

    return new Promise(resolve => {
      axios.delete(`${resource_backend_url}dphotos/${data.photoId}`,{ headers })
      .then(response => {
        commit('setDonor', response.data);
        let notePayload = NotePayload.setNotePayload("picture-donor-delete");
        dispatch('createDonorNote', {notePayload: notePayload, donorId: data.donorId}).then(()=>{
          commit('setDonor', response.data);
          resolve(response.data)
        }).catch(err=>{
          resolve({
            status: 500,
            message: err
          })
        })
        
      })
      .catch(() => {
        resolve({
          status: 500,
          message: "An error occurred while trying to create note. Please contact an administrator.",
          roles: null
        });
      
      })
    });
  },

  

  // Obtener las fotografías del donador por id
  async getDonorPictures({ commit }, donorId) {
    try {
      const response = await axios.get(`${resource_backend_url}photos/${donorId}`,
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );
      commit('setDonor', response.data);
      return response;
    } catch (error) {
      return({
        status: 500,
        message: "An error occurred while trying to upload image(s)."
      })
    }
  },

  async getAllDonorsPictures({ commit }) {
    try {
      const response = await axios.get(`${resource_backend_url}photosp/paths`,
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );
      commit('setDonor', response.data);
      return response;
    } catch (error) {
      return({
        status: 500,
        message: "An error occurred while trying to upload image(s)."
      })
    }
  },


  // Establecer imagen principal
  async setDefaultDonorPicture({ commit }, imgId) {
    //const fs = require('fs');
    try {
      const response = await axios.put(`${resource_backend_url}photoPrincipal/${imgId}`, null,
        {
          headers: {
            //  'Access-Control-Allow-Origin': '*',
            //  'Access-Control-Allow-Headers': 'Content-Type, Authorization',
            //  'Access-Control-Allow-Methods': '*',
            // 'Access-Control-Allow-Headers': 'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin',
            //'Content-Type': 'application/json',
          }
        }
      );
      commit('setDonor', response.data);
      return response.data;
    } catch (error) {
      return({
        status: 500,
        message: "An error occurred while trying to set default image."
      })
    }
  },


  purgeAuth({ commit }) {
    commit('purgeAuth');
  }
}

const mutations = {
  setDonors: (state, contacts) => state.contacts = contacts,

  setDonor: (state, donor) => {
    state.donor = donor
  },

  setDonorAuth: (state, data) => {
    state.donorAuth = data,
    JwtService.saveDonorId(state.donorAuth.results[0].id);
    JwtService.saveDonorName(state.donorAuth.results[0].first_name);
  },

  setError: (state, data) => {
    state. error = data;
  },

  purgeAuth: () => {
    JwtService.destroyToken();
  },

  purgeDonorAuth: (state) => {
    state.donorAuth = {},
    JwtService.destroyDonorId();
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}